import {Input, InputGroup, InputLeftElement, Text} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {FaEnvelope, FaUserAlt} from "react-icons/fa";
import * as React from "react";

export type FullNameFormProps = {
    fullName: string,
    setFullName: (fullName: string) => void;
};

export function FullNameForm({fullName, setFullName} : FullNameFormProps) {
    return (
        <>
            <Text float="left" textAlign="left" fontSize="lg" color="gray.900">Full Name:
            </Text>
            <InputGroup>
                <Input variant='filled'
                       pr='4.5rem'
                       type={'text'}
                       placeholder='Enter your full name ...'
                       value={fullName}
                       onChange={(e:any) => setFullName(e.target.value)}
                />
                <InputLeftElement
                    pointerEvents='none'
                    children={<Icon as={FaUserAlt} color='gray.300'/>}
                />
            </InputGroup>
        </>
    )
}