// Chakra imports
import {Flex, Stat, StatHelpText, StatLabel, StatNumber, useColorModeValue,} from "@chakra-ui/react";
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import IconBox from "../../components/Icons/IconBox";
import React from "react";

const MiniStatistics = ({title, amount, percentage, icon}:any) => {
    const iconTeal = useColorModeValue("teal.300", "teal.300");
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card minH='83px' borderRadius={"20px"}
              background={"#FFFFFF"} border='1px' borderColor='gray.100' p={"10px"} my="10px">
            <CardBody>
                <Flex flexDirection='row' align='center' justify='center' w='100%'>
                    <Stat me='auto'>
                        <StatLabel
                            fontSize='sm'
                            color='gray.400'
                            fontWeight='bold'
                            pb='.1rem'>
                            {title}
                        </StatLabel>
                        <Flex>
                            <StatNumber fontSize='md' color={textColor}>
                                {amount}
                            </StatNumber>
                            <StatHelpText
                                alignSelf='flex-end'
                                justifySelf='flex-end'
                                m='0px'
                                color={"green.400"}
                                fontWeight='bold'
                                ps='3px'
                                fontSize='md'>
                                {percentage}
                            </StatHelpText>
                        </Flex>
                    </Stat>
                    <IconBox as='box' minH="45px" minW="45px" h={"45px"} w={"45px"} bg="#B5D7A5">
                        {icon}
                    </IconBox>
                </Flex>
            </CardBody>
        </Card>
    );
};

export default MiniStatistics;
