import SidebarWithHeader from "./SidebarWithHeader";
import MiniStatistics from "./MiniStatistics";
import {FiBell, FiSearch} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Center, Checkbox,
    Flex,
    Grid, HStack, Image, Input, InputGroup,
    InputRightElement, Link,
    Radio,
    RadioGroup,
    SimpleGrid, Spinner,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue
} from "@chakra-ui/react";
import SalesOverview from "./SalesOverview";
import LineChart from "../../components/Charts/LineChart";
import PieChart from "../../components/Charts/PieChart";
import CardHeader from "../../components/Card/CardHeader";
import Card from "../../components/Card/Card";
import ActiveUsers from "./ActiveUsers";
import BarChart from "../../components/Charts/BarChart";
import {auth} from "../../firebase";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {EditableTable} from "./EditableTable";
import * as XLSX from 'xlsx';
import {handleFileUpload} from "../../util/ExcelFileParser";
import Joyride from 'react-joyride';
import mixpanel from "mixpanel-browser";
import { PricingPopup } from "./PricingPopup";
import {DocumentIcon, GlobeIcon, WalletIcon} from "../../components/Icons/Icons";
import { FaTable } from "react-icons/fa";

export type ColumnEntryType = {
    id: number;
    title: string;
}

const data: ColumnEntryType[] = [
    {
        id: 0,
        title: 'Product'
    },
    {
        id: 1,
        title: 'Price'
    },
    {
        id: 2,
        title: 'Quantity'
    },
]

export function GenerateFormulaPage() {

    const navigate = useNavigate();

    const [displayName, setDisplayName] = useState('Excelly-AI User');
    const [email, setEmail] = useState('Unknown');
    const [userUid, setUserUid] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);

    const [query, setQuery] = useState('');
    const [result, setResult] = useState('');
    const [explanation, setExplanation] = useState('');

    const [noMoreUsageLeft, setNoMoreUsageLeft] = useState(false);

    const [isFirstSignIn, setIsFirstSignIn] = useState(false);

    const [finishedTutorial, setFinishedTutorial] = useState(
        localStorage.getItem('finishedTutorial') === 'true'
    );

    useEffect(() => {
        localStorage.setItem('finishedTutorial', finishedTutorial.toString());
    }, [finishedTutorial]);


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUserUid(user.uid);
                setEmailVerified(user.emailVerified);
                setDisplayName(user.displayName ?? 'Excelly-AI User');
                setEmail(user.email ?? 'Unknown');
                setIsFirstSignIn(user.metadata.creationTime === user.metadata.lastSignInTime);

                mixpanel.identify(user.uid);
                if(user.metadata.creationTime === user.metadata.lastSignInTime) {
                    mixpanel.track('First login');
                }
            } else {
                // User is signed out
                // ...
                navigate("/login");
            }
        });

    }, []);

    const textColor = useColorModeValue("gray.700", "white");

    const [radioValue, setRadioValue] = React.useState('1');

    const [columnSchemaRadioValue, setColumnSchemaRadioValue] = React.useState('1');

    const [parameterSeparatorRadioValue, setParameterSeparatorRadioValue] = React.useState('1');

    const [loading, setLoading] = useState(false);

    const [columnData, setColumnData] = useState(data);

    const [isDragActive, setIsDragActive] = useState(false);

    const [isChecked, setIsChecked] = React.useState(false);

    const [joyrideSteps, setJoyrideSteps] = useState([
        {
            target: '.program-section',
            content: 'Welcome, great to have you on board! 🤗 This tutorial will quickly guide you through Excelly-AI. It will only take a minute, promise! 😎 Let\'s get started right away: First, you can choose whether you want to generate an Excel or Google Sheets formula.',
            disableBeacon: true,
        },
        {
            target: '.column-section',
            content: 'Before you enter your formula prompts, you can specify your column schema 💡 Column schemas allow you to write semantically meaningful prompts 🤓 You have three options here, which we will discuss now.',
        },
        {
            target: '.column-section-1',
            content: 'Option 1️⃣: Manually set your column scheme below ⬇️ This corresponds to the columns in your spreadsheet, for example, the first column is "Product", the second column is "Price", and the third column is "Quantity" 💡 You can add or remove columns by clicking ➕ or ➖. For example, with this column scheme you can write prompts like "Give me the average price of all products in stock" 😎',
        },
        {
            target: '.column-section-2',
            content: 'Option 2️⃣: Determine the column schema automatically by uploading your spreadsheet file: Just drag & drop your *.xlsx file here and Excelly-AI will automatically determine your column schema 🤩 This is currently only supported for Excel 🤓',
        },
        {
            target: '.column-section-3',
            content: 'Option 3️⃣: Do not use a column schema: You can also choose not to use a column schema and have your prompts refer only to column A, column B, and so on 🤗 Instead of writing "Give me the sum of all prices where the quantity is ...", you would write "Give me the sum of all values in column B where the value in C is ..." 💡',
        },
        {
            target: '.comma-semicolon-section',
            content: 'Some Excel versions use commas (e.g. SUM(A1,A2)) instead of semicolons (e.g. SUM(A1;A2)) to separate parameters in formulas 🤔 Select this check box if your Excel version uses commas.',
        },
        {
            target: '.prompt-section',
            content: 'Last step! 🥳 Describe your desired formula here, click "Generate" and let Excelly-AI do the magic 🚀 Your formula will appear below. We hope you enjoy using Excelly-AI! 🥳',
        }
    ]);

    function handleJoyrideCallback(data:any) {
        const { action } = data;

        if(action === "reset") {
            mixpanel.track('Finish tutorial');
            setFinishedTutorial(true);
        }
    }

    const handleCheckboxChange = (event: any) => {
        setIsChecked(event.target.checked);
    };

    function handleDragEnter(event: any) {
        event.preventDefault();
        setIsDragActive(true);
    }

    function handleDragLeave(event: any) {
        event.preventDefault();
        setIsDragActive(false);
    }

    function handleDragOver(event: any) {
        event.preventDefault();
    }

    function handleDrop(event: any) {
        event.preventDefault();
        setIsDragActive(false);

        const files = Array.from(event.dataTransfer.files);
        // @ts-ignore
        const xlsxFiles = files.filter((file) => file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        if (xlsxFiles === undefined || xlsxFiles.length === 0) {
            console.log("Did not receive a file.");
            return;
        }
        if (xlsxFiles.length > 1) {
            console.log("Received too many files.");
            return;
        }

        handleFileUpload(xlsxFiles[0] as File).then(row => {
            if (row === undefined) {
                console.log("No valid row format.");
                return;
            }
            const newData: ColumnEntryType[] = [];

            row.forEach((item, index) => {
                if (item !== undefined && item !== '') {
                    newData.push({id: index, title: item});
                }
            });

            setColumnSchemaRadioValue('1');
            setColumnData(newData);
            mixpanel.track('Upload xlsx file');
        });

    }

    const [isPricingPopupOpen, setIsPricingPopupOpen] = useState(false);

    return <>
        <Joyride
            steps={joyrideSteps}
            continuous={true}
            run={isFirstSignIn && !finishedTutorial}
            callback={handleJoyrideCallback}
            styles={{
                options: {
                    arrowColor: '#e3ffeb',
                    backgroundColor: '#e3ffeb',
                    primaryColor: '#F85E00',
                    overlayColor: 'rgba(0, 0, 0, 0.4)',
                }
            }}/>
        <SidebarWithHeader displayName={displayName}>
            <Flex flexDirection='column'>
                <Grid
                    templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
                    templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
                    gap='24px'
                    mb={{ lg: "26px" }}>

                    <Card p='28px 0px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"}
                          background={"#FFFFFF"} border='1px' borderColor='gray.200'>
                        <CardHeader mb='20px' pl='18px' borderBottom='1px' borderBottomColor='gray.200'>
                            <Text fontSize='xl' color={textColor} fontWeight='bold' mb='16px'>
                                Generate Formula
                            </Text>
                        </CardHeader>
                        <Box w='100%' h={{sm: "100%"}} ps='8px'>
                            <Text fontSize='lg' color={textColor} fontWeight='bold' mt='10px' ml='10px'>
                                Prompt:
                            </Text>

                            <Text fontSize='md' ml="10px" color={textColor} mt='10px'>
                                Please describe the formula below:
                            </Text>

                            <InputGroup className="prompt-section" pr="15px" size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                                <Input
                                    type={'text'}
                                    placeholder='Average price of all products where the quantity is ...'
                                    value={query}
                                    onChange={(e: any) => setQuery(e.target.value)}
                                />
                            </InputGroup>

                            <HStack alignItems="center" mt="10px" ml="5px">
                                <Button disabled={query === ''} onClick={() => {
                                    setLoading(true);
                                    const columnDataParam = columnData.map((entry) => entry.title).join(';');
                                    fetch("https://api.excelly-ai.io/generate-formula?email=" + email + "&type=" + radioValue + "&query=" + query + "&columns=" + columnDataParam + "&useCommas=" + isChecked)
                                        .then((response) => response.json())
                                        .then((data) => {
                                                const res = data['formula'];
                                                if (res === "no-usage-left") {
                                                    setResult('');
                                                    setExplanation('');
                                                    setNoMoreUsageLeft(true);
                                                    setIsPricingPopupOpen(true);
                                                    mixpanel.track('Formula limit reached');
                                                } else {
                                                    setResult(data['formula']);
                                                    setExplanation(data['explanation']);
                                                    setNoMoreUsageLeft(false);
                                                    mixpanel.track('Formula generated');
                                                }
                                                setLoading(false);
                                            }
                                        )
                                        .catch((error) => {
                                            setResult('');
                                            setNoMoreUsageLeft(false);
                                            setLoading(false);
                                            mixpanel.track('Formula generation error');
                                        });
                                }}>
                                    Generate
                                </Button>
                                <Checkbox className="comma-semicolon-section" isChecked={isChecked}
                                          onChange={handleCheckboxChange}>
                                    <Tooltip
                                        label="Choose this option if you want to use commas (e.g. SUM(A1,B5)) instead of semicolons (e.g. SUM(A1;B5)) to separate parameters inside the formula">
                                        Use commas instead of semicolons
                                    </Tooltip>
                                </Checkbox>
                            </HStack>

                            <CardHeader mt='20px' mb='20px' pl='10px'>
                                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                                    Result
                                </Text>
                            </CardHeader>

                            <HStack ml="5px">
                                {loading ? (
                                    <Spinner width="32px" height="32px"/>
                                ) : (
                                    <Image
                                        src="img/icon/logo.png"
                                        alt='icon'
                                        width="32px"
                                        height="32px"/>
                                )}
                                <Text fontSize='lg' color={textColor} fontWeight='bold'>:</Text>
                            </HStack>

                            <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px" pr="15px">
                                <Input
                                    type={'text'}
                                    placeholder='Your generated formula will appear here ...'
                                    readOnly={true}
                                    value={result}
                                />
                                <InputRightElement width='6.5rem'>
                                    <Button h='1.75rem' size='md' marginRight="15px"
                                            onClick={() => {
                                                navigator.clipboard.writeText(result)
                                            }}>
                                        Copy
                                    </Button>
                                </InputRightElement>
                            </InputGroup>

                            <PricingPopup isOpen={isPricingPopupOpen} setIsOpen={setIsPricingPopupOpen}/>

                            {explanation !== '' ? (
                                <>
                                    <CardHeader mt='20px' mb='20px' pl='10px'>
                                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                                            Explanation:
                                        </Text>
                                    </CardHeader>
                                    <Text ml="5px" mt="10px" fontSize='lg' maxWidth="3xl" color={textColor}
                                          fontWeight='bold'>{explanation}</Text>
                                </>
                            ) : null}

                            {noMoreUsageLeft ? (
                                <Text mt="5px" ml="10px">Usage limit reached. Need to generate more formulas?<Link
                                    style={{color: '#009A5E'}} href="https://excelly-ai.io/pricing.html"> Upgrade here!</Link></Text>) : null}

                        </Box>
                    </Card>

                    <Card p='28px 0px 16px 00px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"}
                          background={"#FFFFFF"} border='1px' borderColor='gray.200'>
                        <CardHeader mb='20px' pl='18px' borderBottom='1px' borderBottomColor='gray.200'>
                            <Text fontSize='xl' color={textColor} fontWeight='bold' mb='16px'>
                                Configuration
                            </Text>
                        </CardHeader>
                        <Box className='program-section' pl='9px'>
                            <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px' ml='10px'>
                                Program:
                            </Text>
                            <RadioGroup defaultValue='1' onChange={setRadioValue} value={radioValue} marginLeft="10px">
                                <Stack spacing={5} direction='row'>
                                    <Radio colorScheme='green' value='1'>
                                        <Tooltip label="Choose this option if you want to generate an Excel formula">
                                            Excel
                                        </Tooltip>
                                    </Radio>
                                    <Radio colorScheme='green' value='2'>
                                        <Tooltip label="Choose this option if you want to generate a Google Sheets formula">
                                            Google Sheets
                                        </Tooltip>
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Box>
                        <Box className='column-section' pl='9px'>
                            <Text fontSize='lg' color={textColor} fontWeight='bold' mb='5px' mt='10px' ml='10px'>
                                Column schema:
                            </Text>
                            <RadioGroup defaultValue='1' onChange={setColumnSchemaRadioValue} value={columnSchemaRadioValue}
                                        marginLeft="10px">
                                <Stack spacing={5} direction='row'>
                                    <Radio colorScheme='green' value='1' className="column-section-1">
                                        <Tooltip
                                            label="Manually enter the column schema (e.g. 'Product', 'Price', 'Quantity') below">
                                            Manually enter schema
                                        </Tooltip>
                                    </Radio>
                                    <Radio colorScheme='green' value='2' className="column-section-2"
                                           isDisabled={radioValue === '2'}>
                                        <Tooltip
                                            label="With this option, you can upload an Excel sheet (*.xlsx file), and the column schema is detected automatically. This is only supported for Excel">
                                            Auto-detect schema from *.xlsx file
                                        </Tooltip>
                                    </Radio>
                                    <Radio colorScheme='green' value='3' className="column-section-3">
                                        <Tooltip
                                            label="Choose this option if you want to generate queries based on row and column indices (A1, B5, ...)">
                                            No schema
                                        </Tooltip>
                                    </Radio>
                                </Stack>
                            </RadioGroup>

                            {columnSchemaRadioValue === '1' ? (
                                <>
                                    <Text fontSize='md' ml="10px" color={textColor} mt='20px'>
                                        Please enter the columns of your spreadsheet below.
                                    </Text>
                                    <Text fontSize='md' ml="10px" color={textColor}>
                                        You can edit the columns by clicking on them.
                                    </Text>

                                    <EditableTable columnData={columnData} setColumnData={setColumnData}/>
                                </>
                            ) : null}

                            {columnSchemaRadioValue === '2' ? (
                                <>
                                    <Box
                                        border="2px dashed"
                                        borderColor={isDragActive ? "green.500" : "gray.300"}
                                        borderRadius="md"
                                        p={8}
                                        mt="10px"
                                        ml="10px"
                                        maxW="250px"
                                        textAlign="center"
                                        onDragEnter={handleDragEnter}
                                        onDragLeave={handleDragLeave}
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                    >
                                        <Text fontSize="lg" fontWeight="bold" mb={4}>
                                            Drag and drop *.xlsx files here
                                        </Text>
                                        <Text color="gray.500" fontSize="sm">
                                            (the column schema will be auto-detected)
                                        </Text>
                                    </Box>
                                </>
                            ) : null}
                        </Box>
                    </Card>

                </Grid>
                <SimpleGrid columns={{ sm: 1, md: 1, lg: 2, xl: 2, '2xl': 4 }} spacing='4px' marginTop={{sm: "20px", lg: "100px"}}>
                    <MiniStatistics
                        title={"\"Sum of prices of all products in stock\""}
                        amount={"=SUMIF(C:C;\">0\";B:B)"}
                        percentage={"Excel"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                    <MiniStatistics
                        title={"\"Average price of chairs\""}
                        amount={"=AVERAGEIF(A:A;\"chair\";B:B)"}
                        percentage={"Excel"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                    <MiniStatistics
                        title={"\"Products starting with 'E'?\""}
                        amount={"=FILTER(A:B,LEFT(A:A,1)=\"E\")"}
                        percentage={"GSheets"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                    <MiniStatistics
                        title={"\"Mean value of all prices\""}
                        amount={"=AVERAGE(B:B)"}
                        percentage={"GSheets"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                </SimpleGrid>

            </Flex>

        </SidebarWithHeader>
    </>
}