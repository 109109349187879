// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyDfoJtvft6fh8HlRh-vAbCKeU1ZQVsGaWY",
    authDomain: "excelly-ai-webapp.firebaseapp.com",
    projectId: "excelly-ai-webapp",
    storageBucket: "excelly-ai-webapp.appspot.com",
    messagingSenderId: "793704510827",
    appId: "1:793704510827:web:c3cd1d69f8c2c33646482e",
    measurementId: "G-BS31G5H0X7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);