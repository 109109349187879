import {Box, Button, Flex, Grid, Input, InputGroup, Link, SimpleGrid, Text, useColorModeValue} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import SidebarWithHeader from "./SidebarWithHeader";
import {onAuthStateChanged, sendEmailVerification} from "firebase/auth";
import {auth} from "../../firebase";
import mixpanel from "mixpanel-browser";
import {useNavigate} from "react-router-dom";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {sendPasswordResetEmail} from "@firebase/auth";
import MiniStatistics from "./MiniStatistics";
import {FaTable} from "react-icons/fa";
import FeatureLinkImage from "../../components/Card/FeatureLinkImage";
import BuiltByDevelopers from "../../components/Card/BuildByDevelopers";
import {BsFileSpreadsheet, FaPython} from "react-icons/all";
import {FiCode, FiRepeat, FiSearch} from "react-icons/fi";

export function DashboardPage() {

    const navigate = useNavigate();

    const [displayName, setDisplayName] = useState('Excelly-AI User');
    const [email, setEmail] = useState('Unknown');

    const [userUid, setUserUid] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [license, setLicense] = useState('Free');
    const [usageLeft, setUsageLeft] = useState('');

    const [query, setQuery] = useState('');

    useEffect( () => {
        fetch("https://api.excelly-ai.io/user-plan?email=" + email)
            .then((response) => response.json())
            .then((data) => {
                setLicense(data['plan']);
                if ('usage-left' in data) {
                    setUsageLeft(data['usage-left']);
                }
            })
            .catch((error) => {
                setLicense('Free');
            });
    });

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUserUid(user.uid);
                setEmailVerified(user.emailVerified);
                setDisplayName(user.displayName ?? 'Excelly-AI User');
                setEmail(user.email ?? 'Unknown');
            } else {
                // User is signed out
                navigate("/login");
            }
        });

    }, []);

    useEffect(() => {
        fetch("https://api.excelly-ai.io/user-plan?email=" + email)
            .then((response) => response.json())
            .then((data) => {
                setLicense(data['plan']);
                if ('usage-left' in data) {
                    setUsageLeft(data['usage-left']);
                }
            })
            .catch((error) => {
                setLicense('Free');
            });
    }, [userUid, email]);

    const textColor = useColorModeValue("gray.700", "white");

    const [radioValue, setRadioValue] = React.useState('1')

    return <>
        <SidebarWithHeader displayName={displayName}>
            <Flex flexDirection='column'>
                <Card p='28px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.200' background={"#FFFFFF"}>
                    <CardHeader mb='20px' pl='22px'>
                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                            Welcome, {displayName}! 👋
                        </Text>
                    </CardHeader>
                    <Box w='100%' h={{sm: "100%"}} ps='8px'>
                        {!emailVerified ? (
                            <>
                                <Text fontSize='md' ml="15px" color={textColor} mt='10px'>Please verify your E-Mail by clicking the link we sent you.
                                </Text>
                                <Button mt="20px" ml='10px' onClick={() => {
                                    if(auth.currentUser) {
                                        sendEmailVerification(auth.currentUser).catch((err) => {
                                        });
                                    }
                                }
                                }>Resend E-Mail</Button>
                            </>
                        ) : <Text fontSize='md' ml="15px" color={textColor} mt='10px'>Your E-Mail is verified successfully!</Text>}
                        <Text></Text>

                        {license.startsWith("Free") ? (<Text my="10px" ml="15px" ><Link style={{color: '#009A5E'}} href="https://excelly-ai.io/pricing.html">Limited offer: 20% off the first month of our Basic, Plus and Premium plans! Code: SPREADSHEETS20</Link></Text>) : null }
                    </Box>
                </Card>

                <SimpleGrid columns={{ sm: 1, md: 1, lg: 2, xl: 2, '2xl': 4 }} spacing='4px' marginTop="20px">
                    <MiniStatistics
                        title={"\"Sum of prices of all products in stock\""}
                        amount={"=SUMIF(C:C;\">0\";B:B)"}
                        percentage={"Excel"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                    <MiniStatistics
                        title={"\"Average price of chairs\""}
                        amount={"=AVERAGEIF(A:A;\"chair\";B:B)"}
                        percentage={"Excel"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                    <MiniStatistics
                        title={"\"Products starting with 'E'?\""}
                        amount={"=FILTER(A:B,LEFT(A:A,1)=\"E\")"}
                        percentage={"GSheets"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                    <MiniStatistics
                        title={"\"Mean value of all prices\""}
                        amount={"=AVERAGE(B:B)"}
                        percentage={"GSheets"}
                        icon={<FaTable width={"24px"} height={"24px"} />}
                    />
                </SimpleGrid>

                <Grid
                    templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
                    templateRows={{ md: "1fr auto", lg: "1fr" }}
                    gap='20px'>
                    <Card p='5px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.200' background={"#FFFFFF"}>
                        <BuiltByDevelopers
                            title={"Feature"}
                            name={"Formula Generation"}
                            description={
                                "Turn plain text into powerful formulas! Both Excel and Google Sheets supported. You can also upload your own *.xlsx files to generate formulas based on your specific spreadsheets!"
                            }
                            image={<BsFileSpreadsheet style={{ width: '84px', height: '84px' }}/> }
                            url="generate"
                        />
                    </Card>
                    <Card p='5px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.200' background={"#FFFFFF"}>
                        <BuiltByDevelopers
                            title={"Feature"}
                            name={"Formula Explanation"}
                            description={
                                "Unsure what a specific formula does? Let Excelly-AI explain it to you!"
                            }
                            image={<FiSearch style={{ width: '84px', height: '84px' }}/> }
                            url="explain"
                        />
                    </Card>
                    <Card p='5px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.200' background={"#FFFFFF"}>
                        <BuiltByDevelopers
                            title={"Feature"}
                            name={"Formula Transformation"}
                            description={
                                "Need to transform a formula from Excel to Google Sheets or vice versa? Then this is the right tool for you. Every transformation also comes with an explanation of the key differences!"
                            }
                            image={<FiRepeat style={{ width: '84px', height: '84px' }}/> }
                            url="transform"
                        />
                    </Card>
                    <Card p='5px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.200' background={"#FFFFFF"}>
                        <BuiltByDevelopers
                            title={"Feature"}
                            name={"VBA Generation"}
                            description={
                                "Describe in plain text what you need and let Excelly-AI generate the VBA code for you!"
                            }
                            image={<FiCode style={{ width: '84px', height: '84px' }}/> }
                            url="vba"
                        />
                    </Card>

                </Grid>

            </Flex>
        </SidebarWithHeader>
    </>
}