import * as XLSX from 'xlsx';

export function handleFileUpload(file: File): Promise<string[] | undefined> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const data = reader.result as ArrayBuffer;
            const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });

            // Select the first worksheet
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            // Define the range of cells to read
            const ref = worksheet['!ref'];

            if (ref === undefined) {
                resolve(undefined);
                return;
            }

            const range = XLSX.utils.decode_range(ref);
            range.s.r = 0;
            range.e.r = 0;

            // Read the first row
            const row = [];
            for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
                const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: colIndex });
                const cell = worksheet[cellAddress];
                const cellValue = (cell && cell.v) || undefined;
                row.push(cellValue);
            }

            resolve(row);
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        reader.readAsArrayBuffer(file);
    });
}