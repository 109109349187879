import React, {ReactNode, ReactText} from 'react';
import {
    Avatar,
    Box,
    BoxProps,
    CloseButton,
    Drawer,
    DrawerContent,
    Flex,
    FlexProps,
    HStack,
    Icon,
    IconButton, Image,
    Link,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList, Show,
    Text,
    useColorModeValue,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import {
    FiBell,
    FiChevronDown, FiCode, FiCodepen,
    FiCompass, FiGrid,
    FiHome,
    FiMenu,
    FiMusic, FiPackage, FiPieChart, FiRepeat, FiSearch,
    FiSettings,
    FiStar, FiTrello,
    FiTrendingUp, FiUsers,
} from 'react-icons/fi';
import {IconType} from 'react-icons';
import {Logo} from "../landing/Logo";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {BsFileSpreadsheet, FaPython, HiOutlineDocumentReport} from "react-icons/all";
import {signOut} from "firebase/auth";
import {auth} from "../../firebase";

interface LinkItemProps {
    name: string;
    icon: IconType;
    url: string;
}

const LinkItems: Array<LinkItemProps> = [
    {name: 'Dashboard', icon: FiGrid, url: 'dashboard'},
    {name: 'Generate Formula', icon: BsFileSpreadsheet, url: 'generate'},
    {name: 'Explain Formula', icon: FiSearch, url: 'explain'},
    {name: 'Transform Formula', icon: FiRepeat, url: 'transform'},
    {name: 'Generate VBA', icon: FiCode, url: 'vba'},
    {name: 'Settings', icon: FiSettings, url: 'settings'},
];

export type SidebarWithHeaderProps = {
  children: ReactNode;
  displayName: string;
};

export default function SidebarWithHeader({children, displayName}: SidebarWithHeaderProps) {

    const navigate = useNavigate();

    const onLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
            console.log(error);
        });
    }

    const {isOpen, onOpen, onClose} = useDisclosure();
    return (
        <Box minH="100vh" bg={'gray.050'}>
            <SidebarContent
                onClose={() => onClose}
                display={{base: 'none', md: 'block'}}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose}/>
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav onLogout={onLogout} onOpen={onOpen} displayName={displayName}/>
            <Box ml={{base: 0, md: 60}} p="4">
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({onClose, ...rest}: SidebarProps) => {
    return (
        <Box
            transition="3s ease"
            bg={'white'}
            borderRight="1px"
            borderRightColor={'gray.200'}
            w={{base: 'full', md: 60}}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Logo/>
                <CloseButton display={{base: 'flex', md: 'none'}} onClick={onClose}/>
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} url={link.url}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactText;
    url: string;
}

const NavItem = ({icon, children, url, ...rest}: NavItemProps) => {
    return (
        <Link href={url} style={{textDecoration: 'none'}} _focus={{boxShadow: 'none'}}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: '#F5F9FF'
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'black',
                        }}
                        boxSize={5}
                        as={icon}
                    />
                )}
                <Text>{children}</Text>
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
    onLogout: () => void;
    displayName: string;
}

const MobileNav = ({onOpen, onLogout, displayName, ...rest}: MobileProps) => {
    return (
        <Flex
            ml={{base: 0, md: 60}}
            px={{base: 4, md: 4}}
            height="20"
            alignItems="center"
            bg={'white'}
            borderBottomWidth="1px"
            borderBottomColor={'gray.200'}
            justifyContent={{base: 'space-between', md: 'flex-end'}}
            {...rest}>
            <IconButton
                display={{base: 'flex', md: 'none'}}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu/>}
            />

            <Show below='md'>
                <Image
                    src="img/icon/logo.png"
                    alt='icon'
                    width="32px"
                    height="32px"
                />
            </Show>

            <HStack spacing={{base: '0', md: '6'}}>
                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{boxShadow: 'none'}}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                />
                                <VStack
                                    display={{base: 'none', md: 'flex'}}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">{displayName}</Text>
                                    <Text fontSize="xs" color="gray.600">
                                        User
                                    </Text>
                                </VStack>
                                <Box display={{base: 'none', md: 'flex'}}>
                                    <FiChevronDown/>
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.200')}>
                            <Link href="/settings"><MenuItem>Settings</MenuItem></Link>
                            <Link href="https://excelly-ai.io/pricing.html"><MenuItem>Upgrade</MenuItem></Link>
                            <MenuDivider/>
                            <Link onClick={onLogout}><MenuItem>Sign out</MenuItem></Link>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};