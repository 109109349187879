import {Button, Center, Divider, Heading, Image, Link, Show, Stack, Text} from "@chakra-ui/react";
import * as React from "react";
import {PasswordForm} from "./PasswordForm";
import {EmailForm} from "./EmailForm";
import {LoginButton} from "./LoginButton";
import { VerticalSpace } from "../../VerticalSpace";
import {useState} from "react";
import {auth} from "../../firebase";
import {signInWithEmailAndPassword} from "firebase/auth";
import {useNavigate} from "react-router-dom";


export function LoginPage() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const onLogin = (e:any) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                setErrorMessage('');
                const user = userCredential.user;
                navigate("/dashboard");
                console.log(user);
            })
            .catch((error) => {
                setErrorMessage("Invalid login data.");
            });

    }

    const mystyle = {
        borderTopColor: "#009A5E",
        borderTopWidth: "5px"
    };

    return (
        <>
            <Show above='md'>
                <Image
                    src="img/shape-06.svg"
                    alt='icon'
                    position="absolute"
                    left="10%"
                    top="15%"
                    zIndex="1"
                />
                <Image
                    src="img/shape-03.svg"
                    alt='icon'
                    position="absolute"
                    left="20%"
                    top="55%"
                    zIndex="1"
                />
                <Image
                    src="img/shape-07.svg"
                    alt='icon'
                    position="absolute"
                    left="80%"
                    top="30%"
                    zIndex="1"
                />
            </Show>
            <Center h="100vh" bg="gray.200" zIndex="2">
                <Stack boxShadow="md" bg="whiteAlpha.900" p="16" rounded="xl" style={mystyle}>
                    <a href="https://excelly-ai.io/"><Image
                        src="img/icon/logo.png"
                        alt='icon'
                        mb="8"
                        mx="auto"
                        w={12}
                        h={12}
                    /></a>
                    <Heading float="left" textAlign="left" as="h1">Log in.</Heading>
                    <Divider orientation='horizontal'/>
                    <Text float="left" textAlign="left" fontSize="lg" color="gray.600">
                        Please login with your data entered during registration.
                    </Text>
                    <VerticalSpace space="10px"/>
                    <EmailForm email={email} setEmail={setEmail}/>
                    <VerticalSpace space="5px"/>
                    <PasswordForm password={password} setPassword={setPassword}/>
                    <VerticalSpace space="5px"/>
                    <Button onClick={onLogin} backgroundColor="#009A5E" _hover={{ bg: '#20BA7E' }} color="white" width="100%">Login</Button>
                    <VerticalSpace space="5px"/>
                    <Center><Text float="left" textAlign="left" fontSize="lg" color="gray.600">
                        Don't have an account? <Link href="/signup" style={{color: '#009A5E'}}>Sign Up</Link>
                    </Text></Center>
                    {errorMessage !== '' ? (
                        <>
                            <VerticalSpace space="5px"/>
                            <Text float="left" textAlign="left" fontSize="lg" color="gray.600">
                                {errorMessage}
                            </Text>
                        </>
                        ) : null}
                </Stack>
            </Center>
        </>
    )
}