import {Input, InputGroup, InputLeftElement, Text} from "@chakra-ui/react";
import {Icon} from "@chakra-ui/icons";
import {FaEnvelope} from "react-icons/fa";
import * as React from "react";

export type EmailFormProps = {
    email: string,
    setEmail: (email: string) => void;
};

export function EmailForm({email, setEmail} : EmailFormProps) {
    return (
        <>
            <Text float="left" textAlign="left" fontSize="lg" color="gray.900">E-Mail:
            </Text>
            <InputGroup>
                <Input variant='filled'
                       pr='4.5rem'
                       type={'text'}
                       placeholder='Enter E-Mail ...'
                       value={email}
                       onChange={(e:any) => setEmail(e.target.value)}
                />
                <InputLeftElement
                    pointerEvents='none'
                    children={<Icon as={FaEnvelope} color='gray.300'/>}
                />
            </InputGroup>
        </>
    )
}