import {
    Button,
    Center,
    Container,
    Divider,
    Heading,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Show,
    Stack,
    Text
} from "@chakra-ui/react";
import * as React from "react";
import {PasswordForm} from "./PasswordForm";
import {EmailForm} from "./EmailForm";
import {FullNameForm} from "./FullNameForm";
import {LoginButton} from "./LoginButton";
import { VerticalSpace } from "../../VerticalSpace";
import {createUserWithEmailAndPassword, sendEmailVerification, updateProfile} from "firebase/auth";
import firebase from "firebase/compat";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {auth} from "../../firebase";
import {Icon} from "@chakra-ui/icons";
import {FaEnvelope} from "react-icons/fa";
import "firebase/auth";


export function SignupPage() {

    const navigate = useNavigate();

    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const onSubmit = async (e: any) => {
        e.preventDefault()

        await createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                updateProfile(user, {
                    displayName: fullName
                }).catch((err) => {
                    console.log(err);
                });
                sendEmailVerification(user).catch((err) => {
                    console.log(err);
                });
                navigate("/login")
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                // ..
            });
    }

    const mystyle = {
        borderTopColor: "#009A5E",
        borderTopWidth: "5px"
    };

    return (
        <>
            <Show above='md'>
                <Image
                    src="img/shape-06.svg"
                    alt='icon'
                    position="absolute"
                    left="10%"
                    top="15%"
                    zIndex="1"
                />
                <Image
                    src="img/shape-03.svg"
                    alt='icon'
                    position="absolute"
                    left="20%"
                    top="55%"
                    zIndex="1"
                />
                <Image
                    src="img/shape-07.svg"
                    alt='icon'
                    position="absolute"
                    left="80%"
                    top="30%"
                    zIndex="1"
                />
            </Show>
            <Center h="100vh" bg="gray.200" zIndex="2">
                <Stack boxShadow="md" bg="whiteAlpha.900" p="16" rounded="xl" style={mystyle}>
                    <a href="https://excelly-ai.io/"><Image
                        src="img/icon/logo.png"
                        alt='icon'
                        mb="8"
                        mx="auto"
                        w={12}
                        h={12}
                    /></a>
                    <Heading float="left" textAlign="left" as="h1">Sign up.</Heading>
                    <Divider orientation='horizontal'/>
                    <Text float="left" textAlign="left" fontSize="lg" color="gray.600">
                        By signing up, you agree to our <Link href="https://excelly-ai.io/terms-of-service.html" style={{color: '#009A5E'}}>terms of service</Link>
                    </Text>
                    <Text float="left" textAlign="left" fontSize="lg" color="gray.600">
                        and <Link href="https://excelly-ai.io/privacy.html" style={{color: '#009A5E'}}>privacy policy</Link>. No credit card required.
                    </Text>
                    <VerticalSpace space="10px"/>
                    <FullNameForm fullName={fullName} setFullName={setFullName}/>
                    <VerticalSpace space="10px"/>
                    <EmailForm email={email} setEmail={setEmail}/>
                    <VerticalSpace space="5px"/>
                    <PasswordForm password={password} setPassword={setPassword}/>
                    <VerticalSpace space="5px"/>
                    <Button onClick={onSubmit} backgroundColor="#009A5E" _hover={{ bg: '#20BA7E' }} color="white" width="100%">Sign Up</Button>
                    <VerticalSpace space="5px"/>
                    <Center><Text float="left" textAlign="left" fontSize="lg" color="gray.600">
                        Already have an account? <Link href="/login" style={{color: '#009A5E'}}>Sign In</Link>
                    </Text></Center>
                </Stack>
            </Center>
        </>
    )
}