import {Button, Input, InputGroup, InputLeftElement, InputRightElement, Text} from "@chakra-ui/react";
import * as React from "react";
import {Icon} from "@chakra-ui/icons";
import {FaLock} from "react-icons/fa";

export type PasswordFormProps = {
    password: string,
    setPassword: (password: string) => void;
};

export function PasswordForm({password, setPassword} : PasswordFormProps) {
    const [showPassword, setShowPassword] = React.useState(false)
    const handleShowPasswordClick = () => setShowPassword(!showPassword)

    return (
        <>
            <Text float="left" textAlign="left" fontSize="lg" color="gray.900">Password:
            </Text>
            <InputGroup>
                <Input variant='filled'
                       pr='4.5rem'
                       type={showPassword ? 'text' : 'password'}
                       placeholder='Enter password ...'
                       value={password}
                       onChange={(e:any) => setPassword(e.target.value)}
                />
                <InputLeftElement
                    pointerEvents='none'
                    children={<Icon as={FaLock} color='gray.300'/>}
                />
                <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={handleShowPasswordClick}>
                        {showPassword ? 'Hide' : 'Show'}
                    </Button>
                </InputRightElement>
            </InputGroup>
        </>
    )
}