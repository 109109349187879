import SidebarWithHeader from "./SidebarWithHeader";
import MiniStatistics from "./MiniStatistics";
import {FiBell} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Center,
    Flex,
    Grid, HStack, Image, Input, InputGroup,
    InputRightElement, Link,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import SalesOverview from "./SalesOverview";
import LineChart from "../../components/Charts/LineChart";
import PieChart from "../../components/Charts/PieChart";
import CardHeader from "../../components/Card/CardHeader";
import Card from "../../components/Card/Card";
import ActiveUsers from "./ActiveUsers";
import BarChart from "../../components/Charts/BarChart";
import {auth} from "../../firebase";
import {onAuthStateChanged, sendEmailVerification, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {sendPasswordResetEmail} from "@firebase/auth";

export function SettingsPage() {

    const navigate = useNavigate();

    const [displayName, setDisplayName] = useState('Excelly-AI User');
    const [email, setEmail] = useState('Unknown');

    const [userUid, setUserUid] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [license, setLicense] = useState('Free');
    const [usageLeft, setUsageLeft] = useState('');

    const [query, setQuery] = useState('');

    useEffect( () => {
        fetch("https://api.excelly-ai.io/user-plan?email=" + email)
            .then((response) => response.json())
            .then((data) => {
                setLicense(data['plan']);
                if ('usage-left' in data) {
                    setUsageLeft(data['usage-left']);
                }
            })
            .catch((error) => {
                setLicense('Free');
                console.log(error);
            });
    });

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUserUid(user.uid);
                setEmailVerified(user.emailVerified);
                setDisplayName(user.displayName ?? 'Excelly-AI User');
                setEmail(user.email ?? 'Unknown');
            } else {
                // User is signed out
                navigate("/login");
            }
        });

    }, []);

    useEffect(() => {
        fetch("https://api.excelly-ai.io/user-plan?email=" + email)
            .then((response) => response.json())
            .then((data) => {
                console.log(data['plan']);
                setLicense(data['plan']);
                if ('usage-left' in data) {
                    setUsageLeft(data['usage-left']);
                }
            })
            .catch((error) => {
                setLicense('Free');
                console.log(error);
            });
    }, [userUid, email]);

    const textColor = useColorModeValue("gray.700", "white");

    const [radioValue, setRadioValue] = React.useState('1')

    return <>
        <SidebarWithHeader displayName={displayName}>
            <Card p='28px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.300' background={"#FFFFFF"}>
            <CardHeader mb='20px' pl='22px'>
                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                    Settings
                </Text>
            </CardHeader>
            <Box w='100%' h={{sm: "100%"}} ps='8px'>
                <Text fontSize='md' ml="10px" color={textColor} mt='10px'>
                    Full Name:
                </Text>
                <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                    <Input
                        pr='4.5rem'
                        type={'text'}
                        value={displayName}
                        readOnly={true}
                    />
                </InputGroup>
                <Text fontSize='md' ml="10px" color={textColor} mt='10px'>
                    E-Mail:
                </Text>
                <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                    <Input
                        pr='4.5rem'
                        type={'text'}
                        value={email}
                        readOnly={true}
                    />
                </InputGroup>
                {!emailVerified ? (
                    <>
                        <Text fontSize='md' ml="10px" color={textColor} mt='10px'>Please verify your E-Mail by clicking the link we sent you.
                        </Text>
                        <Button mt="20px" onClick={() => {
                            if(auth.currentUser) {
                                sendEmailVerification(auth.currentUser).catch((err) => {
                                    console.log(err);
                                });
                            }
                        }
                        }>Resend E-Mail</Button>
                    </>
                ) : <Text fontSize='md' ml="10px" color={textColor} mt='10px'>Your E-Mail is verified successfully!</Text>}
                <Text></Text>
                <Button mt="20px" onClick={() => {
                    sendPasswordResetEmail(auth, email)}
                }>Reset password</Button>
                <Text fontSize='md' ml="10px" color={textColor} mt='10px'>Current Plan:
                </Text>
                <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                    <Input
                        pr='4.5rem'
                        type={'text'}
                        value={license}
                        readOnly={true}
                    />
                </InputGroup>

                {usageLeft !== '' ? (<Text mt="5px" ml="10px" >{usageLeft} formulas left this month!</Text>) : null }


                {!license.startsWith("Free") ? <Text mt="15px" ml="10px" fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>Subscription</Text> : null }

                {!license.startsWith("Free") ? (<Text mt="5px" ml="10px" ><Link style={{color: '#009A5E'}} href="https://app.lemonsqueezy.com/my-orders">Here you can manage and cancel your subscription or view your invoices</Link> (or click <Link style={{color: '#009A5E'}} href="https://billing.stripe.com/p/login/dR6bIW6gXeB9dYA6oo">here</Link> if you created your account prior to May 25, 2023)</Text>) : null }

                {license.startsWith("Free") ? (<Text mt="5px" ml="10px" >Need to generate more formulas?<Link style={{color: '#009A5E'}} href="https://excelly-ai.io/pricing.html"> Upgrade here!</Link></Text>) : null }

                {!license.startsWith("Free") ? <Text mt="25px" ml="10px" fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>Support</Text> : null }

                {!license.startsWith("Free") ? (<Text mt="5px" ml="10px" >Need help? You can contact us any time via <Link style={{color: '#009A5E'}} href="mailto:info@excelly-ai.io">info@excelly-ai.io</Link></Text>) : null }

            </Box>
        </Card>
        </SidebarWithHeader>
    </>
}