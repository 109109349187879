import {Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay} from "@chakra-ui/react";


export type PricingPopupProps = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

export function PricingPopup({isOpen, setIsOpen}:PricingPopupProps) {

    function handleCloseClick() {
        setIsOpen(false);
    }

    return (<>
        <Modal isOpen={isOpen} onClose={handleCloseClick} size="xl" isCentered>
            <ModalOverlay/>
            <ModalContent
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="50vh"
                width="max-content"
            >
                <ModalHeader textAlign="center">Free formula limit reached 😓<br/>You are eligible for our 20% off the first month offer! Code: SPREADSHEETS20 🥳</ModalHeader>
                <ModalCloseButton/>
                <ModalBody>
                    <iframe src="https://excelly-ai.io/pricing.html" width="500px" height="600px" title="Pricing"/>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>);
}