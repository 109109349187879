import SidebarWithHeader from "./SidebarWithHeader";
import React, {useEffect, useState} from "react";
import {
    Box, Button,
    HStack, Image, Input, InputGroup,
    Link,
    Radio,
    RadioGroup,
    Spinner,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";

import CardHeader from "../../components/Card/CardHeader";
import Card from "../../components/Card/Card";
import {auth} from "../../firebase";
import {onAuthStateChanged} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export function ExplainPage() {

    const navigate = useNavigate();

    const [displayName, setDisplayName] = useState('Excelly-AI User');
    const [email, setEmail] = useState('Unknown');
    const [userUid, setUserUid] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);

    const [query, setQuery] = useState('');
    const [result, setResult] = useState('');
    const [noMoreUsageLeft, setNoMoreUsageLeft] = useState(false);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUserUid(user.uid);
                setEmailVerified(user.emailVerified);
                setDisplayName(user.displayName ?? 'Excelly-AI User');
                setEmail(user.email ?? 'Unknown');
            } else {
                // User is signed out
                // ...
                navigate("/login");
            }
        });

    }, []);

    const textColor = useColorModeValue("gray.700", "white");

    const [radioValue, setRadioValue] = React.useState('1');

    const [loading, setLoading] = useState(false);

    return <>
        <SidebarWithHeader displayName={displayName}>
            <Card p='28px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.200' background={"#FFFFFF"}>
            <CardHeader mb='20px' pl='22px'>
                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                    Explain Formula
                </Text>
            </CardHeader>
            <Box w='100%' h={{sm: "100%"}} ps='8px'>
                <RadioGroup defaultValue='1' onChange={setRadioValue} value={radioValue} marginLeft="10px">
                    <Stack spacing={5} direction='row'>
                        <Radio colorScheme='green' value='1'>
                            Excel
                        </Radio>
                        <Radio colorScheme='green' value='2'>
                            Google Sheets
                        </Radio>
                    </Stack>
                </RadioGroup>
                <Text fontSize='md' ml="10px" color={textColor} mt='10px'>
                    Please enter the formula below:
                </Text>

                <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                    <Input
                        type={'text'}
                        placeholder='Enter formula ...'
                        value={query}
                        onChange={(e:any) => setQuery(e.target.value)}
                    />
                </InputGroup>

                <Button h='1.75rem' size='md' mt="10px" ml="5px"  marginRight="15px" onClick={() => {
                    setLoading(true);
                    fetch("https://api.excelly-ai.io/explain-formula?email=" + email + "&type=" + radioValue + "&query=" + query)
                        .then((response) => response.json())
                        .then((data) => {
                                const res = data['explanation'];
                                if (res === "no-usage-left") {
                                    setResult('');
                                    setNoMoreUsageLeft(true);
                                } else {
                                    setResult(data['explanation']);
                                }
                                setLoading(false);
                            }
                        )
                        .catch((error) => {
                            setResult('');
                            setNoMoreUsageLeft(false);
                            setLoading(false);
                        });
                }}>
                    Explain
                </Button>

                <CardHeader mt='20px' mb='20px' pl='10px'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                        Result
                    </Text>
                </CardHeader>

                <HStack ml="5px">
                    {loading ? (
                        <Spinner width="32px" height="32px"/>
                    ) : (
                        <Image
                            src="img/icon/logo.png"
                            alt='icon'
                            width="32px"
                            height="32px"/>
                    )}
                    <Text fontSize='lg' color={textColor} fontWeight='bold'>:</Text>
                </HStack>

                <Text ml="5px" mt="10px" fontSize='lg' color={textColor} fontWeight='bold' maxWidth="3xl">{result}</Text>

                {noMoreUsageLeft ? (<Text mt="5px" ml="10px" >Usage limit reached. Need to generate more formulas?<Link style={{color: '#009A5E'}} href="https://excelly-ai.io/pricing.html"> Upgrade here!</Link></Text>) : null }

            </Box>
        </Card>
        </SidebarWithHeader>
    </>
}