import {Heading, HStack, Image} from "@chakra-ui/react";
import * as React from "react";

export function Logo() {
    return <>
        <HStack spacing='10px'>
            <a href="https://excelly-ai.io/"><Image
                src="img/icon/logo.png"
                alt='icon'
                width="32px"
                height="32px"
            /></a>
            <Heading unselectable={"on"} color={"#0288D1"}
                     style={{
                         userSelect: "none",
                         fontSize: 26,
                         background: "-webkit-linear-gradient(45deg, #00A467 30%, #00D96E 90%)",
                         WebkitBackgroundClip: "text",
                         WebkitTextFillColor: "transparent"
                     }}>Excelly-AI</Heading>
        </HStack>
    </>
}
