import {Center, WrapItem} from "@chakra-ui/react";
import * as React from "react";

export type VerticalSpaceProps = {
    space: string;
}

export function VerticalSpace({space}: VerticalSpaceProps) {
    return <WrapItem>
        <Center h={space}>
        </Center>
    </WrapItem>;
}