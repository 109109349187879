import SidebarWithHeader from "./SidebarWithHeader";
import MiniStatistics from "./MiniStatistics";
import {FiBell} from "react-icons/fi";
import React, {useEffect, useState} from "react";
import {
    Box, Button,
    Center,
    Flex,
    Grid, HStack, Image, Input, InputGroup,
    InputRightElement, Link,
    Radio,
    RadioGroup,
    SimpleGrid, Spinner,
    Stack,
    Text,
    useColorModeValue
} from "@chakra-ui/react";
import SalesOverview from "./SalesOverview";
import LineChart from "../../components/Charts/LineChart";
import PieChart from "../../components/Charts/PieChart";
import CardHeader from "../../components/Card/CardHeader";
import Card from "../../components/Card/Card";
import ActiveUsers from "./ActiveUsers";
import BarChart from "../../components/Charts/BarChart";
import {auth} from "../../firebase";
import {onAuthStateChanged, signOut} from "firebase/auth";
import {useNavigate} from "react-router-dom";

export function TransformPage() {

    const navigate = useNavigate();

    const [displayName, setDisplayName] = useState('Excelly-AI User');
    const [email, setEmail] = useState('Unknown');
    const [userUid, setUserUid] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);

    const [query, setQuery] = useState('');
    const [result, setResult] = useState('');
    const [explanation, setExplanation] = useState('');

    const [noMoreUsageLeft, setNoMoreUsageLeft] = useState(false);

    useEffect(()=>{
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setUserUid(user.uid);
                setEmailVerified(user.emailVerified);
                setDisplayName(user.displayName ?? 'Excelly-AI User');
                setEmail(user.email ?? 'Unknown');
            } else {
                // User is signed out
                // ...
                console.log("user is logged out");
                navigate("/login");
            }
        });

    }, []);

    const textColor = useColorModeValue("gray.700", "white");

    const [radioValue, setRadioValue] = React.useState('1');

    const [loading, setLoading] = useState(false);

    return <>
        <SidebarWithHeader displayName={displayName}>
            <Card p='28px 10px 16px 0px' mb={{sm: "26px", lg: "0px"}} mt={"20px"} borderRadius={"20px"} border='1px' borderColor='gray.300' background={"#FFFFFF"}>
            <CardHeader mb='20px' pl='22px'>
                <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                    Transform Formula from Excel to Google Sheets and vice versa
                </Text>
            </CardHeader>
            <Box w='100%' h={{sm: "100%"}} ps='8px'>
                <RadioGroup defaultValue='1' onChange={setRadioValue} value={radioValue} marginLeft="10px">
                    <Stack spacing={5} direction='row'>
                        <Radio colorScheme='green' value='1'>
                            Excel to Google Sheets
                        </Radio>
                        <Radio colorScheme='green' value='2'>
                            Google Sheets to Excel
                        </Radio>
                    </Stack>
                </RadioGroup>

                <Text fontSize='md' ml="10px" color={textColor} mt='10px'>
                    Please enter your formula below:
                </Text>

                <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                    <Input
                        type={'text'}
                        placeholder='Enter formula description ...'
                        value={query}
                        onChange={(e:any) => setQuery(e.target.value)}
                    />
                </InputGroup>

                <Button h='1.75rem' size='md' mt="10px" ml="5px" onClick={() => {
                    setLoading(true);
                    fetch("https://api.excelly-ai.io/transform-formula?email=" + email + "&type=" + radioValue + "&query=" + query)
                        .then((response) => response.json())
                        .then((data) => {
                                const res = data['formula'];
                                if (res === "no-usage-left") {
                                    setResult('');
                                    setExplanation('');
                                    setNoMoreUsageLeft(true);
                                } else {
                                    setResult(data['formula']);
                                    setExplanation(data['explanation']);
                                    setNoMoreUsageLeft(false);
                                }
                                setLoading(false);
                            }
                        )
                        .catch((error) => {
                            setResult('');
                            setNoMoreUsageLeft(false);
                            setLoading(false);
                            console.log(error);
                        });
                }}>
                    Generate
                </Button>

                <CardHeader mt='20px' mb='20px' pl='10px'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                        Result
                    </Text>
                </CardHeader>

                <HStack ml="5px">
                    {loading ? (
                        <Spinner width="32px" height="32px"/>
                    ) : (
                        <Image
                            src="img/icon/logo.png"
                            alt='icon'
                            width="32px"
                            height="32px"/>
                    )}
                    <Text fontSize='lg' color={textColor} fontWeight='bold'>:</Text>
                </HStack>

                <InputGroup size='lg' maxWidth="2xl" marginTop="10px" marginLeft="5px">
                    <Input
                        type={'text'}
                        placeholder='Your transformed formula will appear here ...'
                        readOnly={true}
                        value={result}
                    />
                    <InputRightElement width='6.5rem'>
                        <Button h='1.75rem' size='md' marginRight="15px"
                                onClick={() => {navigator.clipboard.writeText(result)}}>
                            Copy
                        </Button>
                    </InputRightElement>
                </InputGroup>

                {explanation !== '' ? (
                    <>
                        <CardHeader mt='20px' mb='20px' pl='10px'>
                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                            Explanation:
                        </Text>
                        </CardHeader>
                        <Text ml="5px" mt="10px" fontSize='lg' maxWidth="3xl" color={textColor} fontWeight='bold'>{explanation}</Text>
                    </>
                    ) : null }

                {noMoreUsageLeft ? (<Text mt="5px" ml="10px" >Usage limit reached. Need to generate more formulas?<Link style={{color: '#009A5E'}} href="https://excelly-ai.io/pricing.html"> Upgrade here!</Link></Text>) : null }

            </Box>
        </Card>
        </SidebarWithHeader>
    </>
}