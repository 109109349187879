import {Dispatch, SetStateAction, useState} from 'react'
import './EditableTable.css'
import {ColumnEntryType} from "./GenerateFormulaPage";
import mixpanel from "mixpanel-browser";

export type EditableTableProps = {
    columnData: ColumnEntryType[];
    setColumnData: (columnDataArray: ColumnEntryType[]) => void;
}

export function EditableTable({columnData, setColumnData}:EditableTableProps) {

    const onChangeInput = (e:any, columnId:any) => {
        const { name, value } = e.target

        const editData = columnData.map((item) =>
            item.id === columnId && name ? { ...item, [name]: value } : item
        )

        setColumnData(editData)
    }

    return (
        <div className="container" style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '20px'}}>
            <table>
                <thead>
                <tr>
                    {columnData.map(({ id}) => (
                        <th>{String.fromCharCode(Number(id) + 'A'.charCodeAt(0))}</th>
                    ))}
                    <th style={{textAlign:"center", margin: "5px", backgroundColor:"#AECC9D"}}><button onClick={() => {
                        if (columnData.length === 1) {
                            return;
                        }
                        setColumnData(columnData.slice(0, -1));
                        mixpanel.track('Remove column schema row');
                    }}><b>-</b></button></th>
                    <th style={{textAlign:"center", margin: "5px", backgroundColor:"#AECC9D"}}><button onClick={() => {
                        setColumnData([...columnData, {id: columnData[columnData.length - 1].id + 1, title: 'New column'}]);
                        mixpanel.track('Add column schema row');
                    }}><b>+</b></button></th>
                </tr>
                </thead>
                <tbody>

                    <tr key={0}>
                        {columnData.map(({ id, title}) => (
                            <td>
                                <input
                                    name="title"
                                    value={title}
                                    type="text"
                                    onChange={(e) => onChangeInput(e, id)}
                                    placeholder="Column name"
                                />
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
