import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react";
import theme from "./theme";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {LoginPage} from "./views/login/LoginPage";
import {GenerateFormulaPage} from "./views/dashboard/GenerateFormulaPage";
import {SignupPage} from "./views/login/SignupPage";
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "./firebase";
import {
    getAuth
} from "firebase/auth";
import {
    getFirestore
} from "firebase/firestore";
import {ExplainPage} from "./views/dashboard/ExplainPage";
import {SettingsPage} from "./views/dashboard/SettingsPage";
import {VbaPage} from "./views/dashboard/VbaPage";
import {TransformPage} from "./views/dashboard/TransformPage";
import {PythonPage} from "./views/dashboard/PythonPage";
import mixpanel from 'mixpanel-browser';
import { useState } from "react";
import {DashboardPage} from "./views/dashboard/DashboardPage";

export function App() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const db = getFirestore(app);

    mixpanel.init('df4f16ac5ed55de10500af08e4104e56', {debug: false});

    return (
        <>
            <ChakraProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/signup" element={<SignupPage/>}/>
                        <Route path="/dashboard" element={<DashboardPage/>}/>
                        <Route path="/generate" element={<GenerateFormulaPage/>}/>
                        <Route path="/explain" element={<ExplainPage/>}/>
                        <Route path="/transform" element={<TransformPage/>}/>
                        <Route path="/vba" element={<VbaPage/>}/>
                        <Route path="/python" element={<PythonPage/>}/>
                        <Route path="/settings" element={<SettingsPage/>}/>
                    </Routes>
                </BrowserRouter>
            </ChakraProvider>
        </>
    );
}
